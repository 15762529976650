import React from 'react'
import Layout from '../components/common/Layout'

import Section from '../components/common/Section'
import { Fade } from 'react-reveal'
import img from '../components/2019/media/accurate-boy-build-298825.jpg'
import ServiceImage from '../components/common/ServiceImage'

export default ({ data }) => {
  return (
    <Layout>
      <Section section="section section--singular">
        <header className="page-header">
          <h1 className="page-title">Childcare at DEVDAY 19</h1>
          <h4 className="page-subtitle">
            we take care of your offspring so you can take care of your
            conference day
          </h4>
        </header>
        <p>
          We know how hard it can be as a parent to synchronize your schedule
          between your professional and private life. While we cannot tell you
          where to set your priorities on our Saturday event, we'd like to
          support you with a special childcare offering that we negotiated.
        </p>
        <ServiceImage
          wide
          align="left"
          color="primary"
          image={img}
          imgTitle="Photo by Markus Spiske temporausch.com from Pexels"
          size={5}
          cta={
            <a
              href="https://www.kinderbetreuung-berlin.com/"
              target="_blank"
              className="sub-head dashed dashed--reverse dashed--hover in-view__child in-view__child--in"
            >
              kinderbetreuung-berlin.com
            </a>
          }
        >
          <Fade left distance="20px">
            <p>
              Meet <strong>Tim Karbowski</strong> from{' '}
              <a href="https://www.kinderbetreuung-berlin.com/" target="_blank">
                kinderbetreuung-berlin.com
              </a>
              : He and his staff come to your house and take care of your child
              while you are free to enjoy DEVDAY 19. Tim's offering is
              completely oriented on the child's needs: He and each of his staff
              are licensed state-approved nursery school teachers who have
              experience with children of all ages. All children are insured
              during the childcare. His daily business is picking up children
              from kindergarten or school, bringing them home and taking care of
              them until the parents return. He believes that it's best for
              children to take care of them in their known environment: at home.
            </p>
            <p>
              If that sounds like an option for you, here's what we can offer:{' '}
              <a href="mailto:kontakt@tim-karbowski.de?subject=Child Care DEVDAY19&body=DEV Kids">
                <strong>Drop Tim an email</strong>
              </a>{' '}
              and mention the codeword <mark>DEV Kids</mark> along with your
              unique Eventbrite <b>ticket number</b>. He will then deduct a{' '}
              <b>40% discount</b> on the total price.
            </p>
          </Fade>
        </ServiceImage>
      </Section>
    </Layout>
  )
}

/*export const query = graphql`
  {}
`*/
